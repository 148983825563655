import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import NewsList from "../components/newslist"
import SEO from "../components/seo"



class PageTemplate extends Component {

  render() {
    const landingPage = this.props.data.allNodeLandingPage.edges[0].node
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const allAlerts = this.props.data.allNodeAlert
    const allNews = this.props.data.allNodeNews.edges
    return (
        <Layout >
        <SEO
            title={landingPage.relationships.field_hero.field_title_html.value + ' - News'}
            metaData={null}
            locationOrigin={this.props.location.origin}
            domen={this.props.location.origin}
        />

        <div className={"site-content sub-landing_page node-news landing-news-page"}>
          {landingPage.relationships.field_hero ? (
            <TopHero landing={landingPage} menu={groupMenu} showNews={landingPage.field_show_news_in_menu} showEvents={landingPage.field_show_events_in_menu} showDocs={landingPage.field_show_forms_docs_in_menu} islanding="0" alerts={allAlerts} />
          ) : (
            null
          )}

          <section className="breadcrumb">
            <div className="container">
              <ul className="uk-breadcrumb breadcrumb-list">
                <li><Link to="/">Home</Link></li>
                {landingPage.relationships.field_parent_agency !== null && landingPage.relationships.field_parent_agency.relationships !== undefined && landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html ? (
                  <li><Link to={landingPage.relationships.field_parent_agency.path.alias}>{landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html.value}</Link></li>
                ) : (
                  null
                )}
                <li><Link to={landingPage.path.alias}>{landingPage.relationships.field_hero.field_title_html.value}</Link></li>
                <li className="uk-disabled">News</li>
              </ul>
            </div>
          </section>

          <section className="all-news">
            <div className="container">
              {allNews.length === 0 ? (
                <p>There are currently no news to display.</p>
              ) : (
                <NewsList news={allNews} relarray={null} currentDepartment={landingPage} />
              )}

            </div>
          </section>

        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($groupmenu: String, $landing: Int, $groupid: Int) {

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
        ...node__alertEdgeFragment
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }

    allNodeNews(sort: {fields: created, order: DESC},filter: {relationships: {group_content__departments_group_node_news: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          created
          title
          body {
            value
          }
          path {
            alias
          }
          field_featured_post
          relationships {
            field_media_image {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
            group_content__departments_group_node_news {
              relationships {
                gid {
                  drupal_internal__id
                  label
                }
              }
            }
          }
        }
      }
    }

    allNodeLandingPage(filter: {drupal_internal__nid: {eq: $landing}}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
  }
`
