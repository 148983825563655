import React, { Component } from "react"
import {Link } from "gatsby"

class NewsList extends Component {

  render(props) {
    var moment = require('moment'); // require
    const currentNews = this.props.news
    const landingPageRel = this.props.relarray
    const landingPage = this.props.currentDepartment
    var indexSpace

    return (
      <>
      {currentNews && currentNews.map((news,key) => (
         <div className="all-news-wrapper" key={"news"+key}>
           <div className="uk-grid" data-uk-grid>

               {news.node.relationships.field_media_image && news.node.relationships.field_media_image.relationships.field_media_image ? (
                <div className="uk-width-1-4@m">
                 <div className="press-image" style={{backgroundImage: `url(${news.node.relationships.field_media_image.relationships.field_media_image.uri.url})`}}>
                  <Link to={news.node.path.alias}></Link>
                 </div>
                </div>
               ) : (
                 null
               )}

             <div className="uk-width-expand">
               <div className="news-content">
                 <div className="news-content-date-and-department">
                  <div className="news-content-date">{moment(news.node.created).format("dddd, MMMM D")}</div>
                    <div className="news-content-department">
                      {
                        news.node.relationships.group_content__departments_group_node_news === null ? (
                          <span>County News &amp; Features</span>
                        ) : (
                          landingPageRel !== null ? (
                            news.node.relationships.group_content__departments_group_node_news && news.node.relationships.group_content__departments_group_node_news.map((group,key) => (
                              key > 0 ? (
                                <Link key={"link"+key} to={landingPageRel[group.relationships.gid.drupal_internal__id]['path']} dangerouslySetInnerHTML={{ __html: ", "+landingPageRel[group.relationships.gid.drupal_internal__id]['title'] }} />
                              ) : (
                                <Link key={"link"+key} to={landingPageRel[group.relationships.gid.drupal_internal__id]['path']} dangerouslySetInnerHTML={{ __html: landingPageRel[group.relationships.gid.drupal_internal__id]['title'] }} />
                              )
                            ))
                          ) : (
                            landingPage !== null ? (
                              <Link to={landingPage.path.alias}>
                              {landingPage.relationships.field_hero.field_title_html.value}
                              </Link>
                            ) : (
                              null
                            )
                          )
                        )
                      }
                    </div>
                 </div>
                 <div className="news-content-body">
                   <Link className="title-link" to={news.node.path.alias}>
                    <h2 className="h3" dangerouslySetInnerHTML={{ __html: news.node.title }} />
                   </Link>
                    {news.node.body !== null ? (
                      indexSpace = parseFloat(news.node.body.value.trim().replace(/(<([^>]+)>)/gi, "").slice(0,150).lastIndexOf(' ')),
                      <div dangerouslySetInnerHTML={{ __html: news.node.body.value.trim().replace(/(<([^>]+)>)/gi, "").slice(0,150).substring(0,indexSpace) + " ..." }} />
                    ) : (
                      null
                    )}
                 </div>
               </div>
             </div>
           </div>
         </div>
       ))}
      </>
      )
  }
}

export default NewsList
